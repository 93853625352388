<template>
 <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    

    $route(route) {
      try {
        document.title = route?.meta.title || "售后管理系统";
      } catch (e) {
        document.title = "售后管理系统";
      }
    },
  },
};
</script>

<style lang="less">
/*隐藏滚动条*/

</style>

