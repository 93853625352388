import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/ESClientOrder',
    meta: { title: 'Submit Ticket' },
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/ESClientOrder/index.vue')

  },

]

const router = new VueRouter({
  routes
})


router.beforeEach((to, form, next) => {


  if (to.path == '/') {

    next('/ESClientOrder')
  } else {
    next()
  }
})

export default router
